@charset "UTF-8";
@import url("./assets/css/font-awesome.min.css");

html,
body {
  font-family: "Lato", sans-serif;
  font-weight: normal;
}
html,
body {
  width: 100%;
  min-height: 100%;
  height: auto;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: "Lato", sans-serif;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  -webkit-text-size-adjust: none;
}

a:hover {
  text-decoration: none;
  color: #008572;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #222222;
}

/* Box Model */

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.sf-page-layout {
  min-height: 100%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* common css style */

.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-x: unset;
}

.row {
  margin-right: -14px;
  margin-left: -14px;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding-right: 14px;
  padding-left: 14px;
}

.logo {
  /* background: #7b1fa2; */
  height: 50px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  position: relative;
}

img.sf-logo {
  width: 300px;
}
.sf-login {
  width: 470px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.no-shadow {
  box-shadow: none !important;
}

input[type="submit"],
input[type="reset"],
input[type="button"],
button,
.button {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  background-color: #5a5a5a;
  border-radius: 0;
  border: 0;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  height: 2.85em;
  line-height: 2.95em;
  padding: 0 1.5em;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
}

h2.sf-lg-heading {
  font-size: 30px;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  color: #656565;
  line-height: normal;
  margin: 15px 0 25px;
  padding: 0;
}

.userTxtbox label,
.passTxtbox label {
  display: none;
}
.userTxtbox input,
.passTxtbox input {
  font-size: 18px;
  color: #818181;
  border-radius: 4px;
  height: 45px;
  width: 100%;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  line-height: 45px;
  border: 1px solid #ddd;
  background: #fff;
  padding-left: 38px;
}
fieldset.userTxtbox,
fieldset.passTxtbox {
  position: relative;
}

svg.svg-ico-f {
  fill: #ddd;
  position: absolute;
  left: 10px;
  top: 13px;
  width: 20px;
  height: 20px;
}
fieldset.passTxtbox svg.svg-ico-f {
  top: 14px;
}
.password-strength-meter {
  text-align: left;
  position: absolute;
  right: 10px;
  top: 8px;
  width: 20%;
}

button:focus,
button:hover,
button:focus-within {
  outline: none;
}

.normal-bnt {
  background: transparent;
  color: #03a791;
  font-size: 12px;
  font-weight: 500;
  padding: 0;
  display: flex;
  height: auto;
  line-height: normal;
}
.ccalnd-icon {
  position: absolute;
  right: 10px;
  color: #03a791;
  top: 7px;
  font-size: 13px;
}
.bnt:disabled {
  background: rgba(45, 59, 72, 0.1) !important;
  color: rgba(45, 59, 72, 0.29) !important;
  border: 0 !important;
}

.bnt:disabled {
  background: rgba(45, 59, 72, 0.1) !important;
  color: rgba(45, 59, 72, 0.29) !important;
  border: 0 !important;
}
/* has scroll on button click */

.sf-scroll-bar {
  overflow-x: hidden;
  overflow-y: scroll;
}
.sf-scroll-bar::-webkit-scrollbar {
  width: 2px;
  border-radius: 100px;
}
.sf-scroll-bar::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.sf-scroll-bar::-webkit-scrollbar-thumb {
  background: rgba(45, 59, 72, 0.1);
}
.sf-scroll-bar:hover::-webkit-scrollbar-thumb {
  background: rgba(45, 59, 72, 0.2);
}

/* all scroll heights */
.h-400 {
  max-height: 500px;
  min-height: auto;
}

/* Progress bar styling */

.password-strength-meter progress {
  border-radius: 20px;
}
.password-strength-meter progress::-webkit-progress-bar {
  border-radius: 20px;
}
.password-strength-meter progress::-webkit-progress-value {
  border-radius: 20px;
}
.password-strength-meter progress::-moz-progress-bar {
  border-radius: 20px;
}

.userTxtbox span,
.passTxtbox span {
  color: #ff151f;
  font-size: 16px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
}

.sf-btn {
  background: #03a791;
  border-radius: 4px;
  height: 45px;
  color: #fff;
  line-height: 45px;
  width: 100%;
  margin: 0;
  font-size: 18px;
  font-weight: normal;
}

.fldset-box {
  margin-bottom: 15px;
}

.row-two-col {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: -10px;
  margin-right: -10px;
}
.row-two-col .fldset-box {
  padding: 0 10px;
}

.row-two-col svg.svg-ico-f {
  left: 20px;
}

@media screen and (max-width: 500px) {
  .row-two-col {
    display: block;
  }
}

/* custom checkbox style */

.rember-me .styled-checkbox {
  position: absolute;
  opacity: 0;
}
.rember-me .styled-checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
  display: inline;
  font-size: 13px;
  font-weight: 400;
  color: #222222;
}
.rember-me .styled-checkbox + label:before {
  content: "";
  left: -20px;
  display: inline-block;
  vertical-align: text-top;
  width: 17px;
  height: 17px;
  background: #fff;
  border: 1px solid #03a791 !important;
  top: 0px;
  border-radius: 2px;
  margin-right: 10px;
}
.rember-me .styled-checkbox:hover + label:before {
  background: #03a791;
}
.rember-me .styled-checkbox:focus + label:before {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}
.rember-me .styled-checkbox:checked + label:before {
  background: #03a791;
  content: "check";
  font-family: "Material Icons";
  color: #fff;
  font-size: 14px;
  margin-right: 6px;
}
.rember-me .styled-checkbox:disabled + label {
  color: #b8b8b8;
  cursor: auto;
}
.rember-me .styled-checkbox:disabled + label:before {
  box-shadow: none;
  background: #ddd;
}
.rember-me .styled-checkbox:checked + label:after {
  content: "";
  position: absolute;
  left: -17px;
  top: 8px;
  background: #fff;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
    4px -6px 0 white, 4px -8px 0 white;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.forgt-pass {
  margin: 10px 0;
  color: #848484;
  font-size: 14px;
  font-weight: 400;
}
.forgt-pass a {
  color: #03a791;
  text-decoration: none;
}
.forgt-pass a:hover {
  color: #222222;
}

/* Absolute Center Spinner */
.sk-loder-st {
  position: fixed;
  left: 0;
  width: 100%;
  top: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.25);
  height: 100%;
}
.sk-circle {
  margin: 0 auto;
  width: 40px;
  height: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.sk-circle .sk-child:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #03a791;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}
.sk-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}
.sk-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}
.sk-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sk-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}
.sk-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}
.sk-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.sk-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}
.sk-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}
.sk-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.sk-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}
.sk-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}
.sk-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.sk-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.sk-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.sk-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.sk-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.sk-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.sk-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.sk-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.sk-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.sk-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.sk-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/*  Alert */

.alert-danger {
  color: #ff151f;
}

/* user profile  */

a.usr-profile {
  color: #a1a7ad;
}

a.usr-profile:hover {
  color: #03a791;
}

img.ds-sf-logo {
  width: auto;
  height: 70%;
  margin-top: 12px;
}

.sf-chkbx-group.sf-updt-roles {
  padding: 2px 0;
}

.sf-chkbx-group.sf-updt-roles .ant-checkbox-wrapper {
  flex: 0 0 50%;
  margin: 0 0 10px;
}

.dsb-logo img {
  width: auto;
  height: 35px;
}

.logo.dsb-logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px;
}
.logo.dsb-logo strong {
  font-size: 18px;
  padding-left: 10px;
  color: #fff;
  font-weight: 600;
  display: block;
  opacity: 1;
  transition: 0.5s all;
  width: 130px;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
}
.ant-layout-sider-collapsed .logo.dsb-logo strong {
  display: none;
  opacity: 0;
  width: 0px;
}
.ant-layout-sider-collapsed .logo.dsb-logo {
  justify-content: center;
}
.sett-badge {
  margin-left: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
  line-height: normal;
}

.sett-badge i {
  font-size: 18px;
  color: rgba(45, 59, 72, 0.45);
}
.sett-badge:hover i.anticon,
.pro-sett a:hover .usr-txt {
  color: #03a791;
}

.sett-badge .ant-dropdown-open .usr-txt,
.pro-sett .ant-dropdown-open i,
.sett-badge .notifi-drop.ant-dropdown-open i {
  color: #03a791;
}

.notifi-drop {
  height: 100%;
  display: flex;
  align-items: center;
}

.pro-sett a.ant-dropdown-link {
  color: #a1a7ad;
  display: flex;
  align-items: center;
  height: 100%;
}
.pro-sett .usr-txt {
  padding: 0 7px;
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  color: rgba(45, 59, 72, 0.45);
}
.sett-badge.pro-sett i.anticon {
  font-size: 13px;
}

li.ant-dropdown-menu-item {
  white-space: normal;
}

.user-details .user-name {
  display: inline-block;
  width: 100%;
  font-size: 15px;
  font-weight: 600;
  color: #4a4a4a;
}
.user-details .user-id {
  display: inline-block;
  width: 100%;
  font-size: 13px;
  font-weight: 400;
}

.ant-dropdown-menu-item a:hover .user-name {
  color: #fff;
}

.ant-dropdown-menu-item > i:first-child,
.ant-dropdown-menu-submenu-title > i:first-child {
  min-width: 12px;
  margin-right: 8px;
}

.ant-dropdown-menu-item > a:not([href]):not([tabindex]) {
  color: rgba(0, 0, 0, 0.65);
}

.dash-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  background: #fff;
  min-height: 80px;
}

.page-mn-hd {
  font-size: 21px;
  font-weight: 500;
  color: #222;
  margin: 0;
  text-align: left;
  line-height: normal;
  display: flex;
  align-items: center;
}
.page-mn-hd i {
  margin-right: 10px;
  font-size: 15px;
  color: #7b1fa2;
}

.bnt {
  font-size: 16px;
  text-align: center;
  border-radius: 4px;
  font-weight: 600;
  height: 40px;
  display: inline-block;
  line-height: 38px;
  border: none;
  background: #fff;
  color: #03a791;
  border: 1px solid #03a791;
  transition: 0.5s all;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bnt i.material-icons {
  font-size: 20px;
  padding-right: 8px;
}
.bnt-active,
input[type="submit"].bnt-active {
  background: #03a791;
  color: #fff;
  outline: none;
}
.bnt-active:hover,
input[type="submit"].bnt-active:hover {
  background: #008572;
  color: #fff;
  border-color: #008572;
}

.multibnt .btn-hs-icon {
  margin-left: -5px;
  margin-right: -5px;
}
.multibnt .btn-hs-icon .bnt {
  width: 100%;
  margin: 0 5px;
  height: 32px;
  font-size: 15px;
  font-weight: bold;
  min-width: auto;
}

.bntw-auto.multibnt .btn-hs-icon .bnt {
  width: 100px;
}
.sc-doc-bnt .btn-hs-icon {
  padding-left: 10px;
}

/* steper */

.sf-steps-status {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.sf-steps-row {
  display: flex;
  justify-content: space-between;
}

.sf-st-item .iconbx,
.sf-prog i {
  background: #eceeef;
  width: 40px;
  height: 40px;
  display: inline-block;
  text-align: center;
  font-weight: 500;
  line-height: 47px;
  border-radius: 50px;
  position: relative;
  z-index: 2;
}

.sf-st-item .iconbx i,
.sf-prog i {
  font-size: 20px;
  font-style: normal;
  color: rgba(169, 165, 170, 0.45);
}

.active.sf-st-item .iconbx {
  font-family: "Material Icons";
}
.active.sf-st-item .iconbx:before {
  position: absolute;
  right: -8px;
  top: 0;
  content: "check";
  font-size: 10px;
  background: #03a791;
  border-radius: 50px;
  height: 14px;
  width: 14px;
  line-height: 14px;
  color: #fff;
}

.sf-st-item.sf-prog.active i {
  border-color: #03a791;
}

.sf-st-item.sf-prog .ant-progress-circle-trail {
  stroke: #d5d8da !important;
}

.sf-st-item.sf-prog .ant-progress-circle .ant-progress-text {
  color: #57626d;
  font-weight: 600;
  font-size: 11px;
}

.sf-st-item {
  padding: 0 20px;
  position: relative;
  text-align: center;
  color: #57626d;
}
.sf-st-item:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  background: #eceeef;
  left: 52%;
  top: 20px;
  z-index: 1;
}

.sf-st-item.sf-prog:after,
.sf-st-item.no-bp:after {
  content: none;
}
.sf-steps-row .sf-st-item:last-child,
.sf-st-item.no-bp {
  padding-right: 0;
}
.sf-steps-row .sf-st-item:last-child:after {
  background: transparent;
}
.sf-st-item:first-child {
  padding-left: 0;
}
.sf-st-item.sf-prog {
  padding: 0;
  margin-left: 50px;
}
.sf-st-item.sf-prog i {
  background: transparent;
  line-height: 40px;
  font-size: 11px;
  font-weight: 600;
  color: #57626d;
}

.sf-st-item span {
  display: inline-block;
  width: 100%;
  font-size: 13px;
  color: rgba(45, 59, 72, 0.8);
}
.sf-step-2 .sf-st-item {
  width: 50%;
}

.usr-profile {
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

/* --------- main container ------------- */

.sf-card-wrap {
  padding: 15px;
  box-shadow: 0 2px 4px 0px rgba(45, 59, 72, 0.1);
  background: #fff;
  border-radius: 4px;
}

.sf-card-wrap .sf-card {
  box-shadow: 0 2px 4px 2px rgba(45, 59, 72, 0.1);
}

.sf-card {
  background: #fff;
  padding: 0;
  text-align: left;
  border-radius: 4px;
}

.sf-card-body,
.sf-card-head,
.sf-card-footer {
  padding: 15px;
}

.main-container {
  padding: 20px 15px 20px 33px;
  margin: 0;
  box-shadow: 4px 0 5px rgba(0, 0, 0, 0.1);
  background: rgba(229, 225, 231, 0.24);
  min-height: calc(100vh - 130px);
}

.srch-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.srch-box {
  border: 1px solid #ddd;
  color: #a1a7ad;
  border-radius: 4px;
  height: 38px;
  padding: 5px 10px;
  margin: 10px 0;
}

.srch-box input.srch-input {
  border: none;
  background: transparent;
  box-shadow: none;
  padding: 0 10px;
  color: #222222;
  font-weight: 400;
}
.srch-box .srch-btn {
  padding: 0;
  background: transparent;
  color: #a1a7ad !important;
  box-shadow: none;
  outline: none;
}
.srch-box .srch-btn:hover {
  color: #222222 !important;
}
.sf-service-list .ant-avatar {
  background: #7b1fa2;
  padding: 0;
  border: 1px solid #e0e8e7;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Auto search  */

.auto-search-txt {
  padding: 5px;
}

.auto-search-txt .ant-select-auto-complete.ant-select-lg .ant-input {
  border: 1px solid rgba(45, 59, 72, 0.08);
  color: #222;
  border-radius: 50px;
  height: 38px;
  padding: 5px 10px;
  margin: 0;
  font-weight: 400;
}

.auto-search-txt
  .ant-select-auto-complete.ant-select
  .ant-select-selection__placeholder {
  margin: auto;
  transform: translateY(-50%);
  left: 15px;
}
.auto-search-txt li.ant-select-search {
  padding-left: 0;
}
.search-lists .ant-input-affix-wrapper .ant-input-suffix {
  right: 0;
  background: #03a791;
  color: #fff;
  padding: 11px;
  border-radius: 50px;
  border: 1px solid #03a791;
}

.auto-search-txt .ant-input:hover,
.auto-search-txt .ant-input:focus {
  box-shadow: 0 0 0 1px rgba(3, 167, 145, 0.3);
}

.auto-search-txt .anticon {
  color: #fff;
}

/* Search 10 July 2019 */

.sf-searchbar {
  flex: 0 0 33.33%;
  transition: 0.3s all;
  position: relative !important;
}

.sf-searchbar .sf-card {
  width: calc(100% - 4px);
  border-radius: 4px 0 4px 4px;
}
.sf-searchbar.closed .sf-card {
  overflow: hidden;
}
.sf-searchbar.closed {
  max-width: 100px;
  overflow: hidden;
  flex: 0 0 100px;
}
.bnt-searchbar {
  background: #fff;
  color: #03a791 !important;
  padding: 6px 6px;
  border-radius: 0 8px 8px 0;
  position: absolute;
  right: 0;
  height: 32px;
  line-height: 21px;
  width: 25px;
  display: block;
  top: 0;
  font-size: 10px;
  box-shadow: 1px 0 0 rgba(45, 59, 72, 0.1);
  outline: none;
}
.bnt-searchbar:hover {
  background: #fff;
}
.bnt-searchbar:focus {
  outline: none;
}
.closed .search-lists .ant-input-affix-wrapper .ant-input-suffix {
  right: 8px;
}
.closed .auto-search-txt .ant-select-auto-complete.ant-select-lg .ant-input {
  visibility: hidden;
}
.search-lists .ant-select-selection {
  position: relative;
}

.closed .bnt-searchbar i.anticon {
  transform: rotate(180deg);
}

/* search click button expance colleps */

.col-lg-2,
.col-md-2,
.col-sm-2,
.col-xs-2,
.col-lg-3,
.col-md-3,
.col-sm-3,
.col-xs-3,
.col-lg-4,
.col-md-4,
.col-sm-4,
.col-xs-4,
.col-lg-5,
.col-md-5,
.col-sm-5,
.col-xs-5,
.col-lg-6,
.col-md-6,
.col-sm-6,
.col-xs-6,
.col-lg-7,
.col-md-7,
.col-sm-7,
.col-xs-7,
.col-lg-8,
.col-md-8,
.col-sm-8,
.col-xs-8,
.col-lg-9,
.col-md-9,
.col-sm-9,
.col-xs-9,
.col-lg-10,
.col-md-10,
.col-sm-10,
.col-xs-10,
.col-lg-11,
.col-md-11,
.col-sm-11,
.col-xs-11,
.col-lg-12,
.col-md-12,
.col-sm-12,
.col-xs-12 {
  transition: 0.3s all;
}

.col-md-srch {
  max-width: calc(100% - 100px);
  flex: 0 0 calc(100% - 100px);
  transition: 0.3s all;
}

/* -------- end ----------- */

.sf-service-list.ant-list-split .ant-list-item {
  padding: 0;
  border: none;
}

.sf-service-list.ant-list-split .ant-list-item a {
  border-bottom: 1px solid transparent;
  border-top: 1px solid rgba(45, 59, 72, 0.06);
  padding: 10px 15px;
  display: inline-block;
  width: 100%;
  transition: 0.5s all;
}
.sf-service-list.ant-list-split .ant-list-item:last-child a {
  border-bottom: 1px solid rgba(45, 59, 72, 0.06);
}
.sf-service-list.ant-list-split .ant-list-item a:hover,
.sf-service-list.ant-list-split .ant-list-item.active a {
  background: rgba(3, 167, 145, 0.06);
  color: #03a791;
}
.sf-service-list.ant-list-split .ant-list-item.active a {
  border-bottom: 1px solid rgba(45, 59, 72, 0.06);
}

.sf-service-list.ant-list-split
  .ant-list-item
  a:hover
  .ant-list-item-meta-title,
.sf-service-list.ant-list-split
  .ant-list-item.active
  a
  .ant-list-item-meta-title {
  color: #03a791;
}

.disable-lists {
  opacity: 0.4;
}

.ant-list-item-meta-title {
  text-transform: capitalize;
  color: #222;
  font-weight: 500;
}

.sf-service-list .ant-list-item-meta-description {
  color: #57626d;
  font-size: 13px;
  line-height: normal;
}
.v-all-org {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  background: transparent !important;
  color: #03a791 !important;
  height: auto;
  white-space: pre-line;
  word-break: break-word;
  line-height: normal;
  width: 100%;
  padding: 0;
}
.v-all-org:focus {
  outline: none;
}
.v-all-org:hover {
  text-decoration: underline;
}

.sf-pg-heading {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
}
.abb-1 {
  border-bottom: 1px solid #eaebec;
}

.info-btn .more-info {
  height: 24px;
  width: 24px;
  display: inline-block;
  color: rgba(45, 59, 72, 0.45);
  font-size: 19px;
  text-align: center;
  line-height: 26px;
  border-radius: 50px;
  cursor: pointer;
}
.info-btn .more-info.ant-dropdown-open {
  background: #f1f1f1;
  color: #03a791;
}
.info-btn.disable-dot-menu .more-info {
  color: rgba(45, 59, 72, 0.45);
  cursor: not-allowed;
}
.sf-form label {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #222;
}
.sf-form input[type="text"],
.sf-form input[type="name"],
.sf-form input[type="number"],
textarea,
.sf-form select {
  height: 34px;
  border-radius: 4px;
  background: #fff;
  border: 1px solid rgba(45, 59, 72, 0.08);
  color: rgba(45, 59, 72, 0.9);
  font-weight: 400;
  width: 100%;
  padding: 10px;
  font-size: 14px;
}

.sf-form input[type="text"]:focus,
.sf-form input[type="name"]:focus,
textarea:focus {
  box-shadow: 0 0 0 1px rgba(3, 167, 145, 0.3);
  outline: none;
}

.sf-form textarea {
  height: 80px;
  resize: none;
}

.btn-hs-icon {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

/* button has icons */

.btn-hs-icon .bnt {
  height: 40px;
  line-height: normal;
  font-size: 17px;
  text-align: center;
  min-width: 130px;
}

.btn-hs-icon .bnt-normal {
  background: #fff;
  color: #03a791;
}

.btn-hs-icon .bnt-normal:hover {
  background: #03a791;
  color: #fff;
}

.btn-hs-icon .bnt i {
  font-size: 15px;
}

.sf-sm-hd {
  font-size: 14px;
  font-weight: 500;
}

.sf-xs-hd {
  font-size: 13px;
  font-weight: 500;
}

.sm-bnt .bnt {
  height: 32px;
  font-size: 15px;
  line-height: 31px;
}

.sf-xs-hd .uhd-name {
  font-size: 13px;
  color: #a1a7ad;
  font-weight: 400;
  padding-left: 5px;
}

/* closed button style */

button.closed-btn {
  padding: 0;
  background: transparent;
  color: #a1a7ad;
  font-size: 14px;
  outline: none;
  height: auto;
  line-height: normal;
}
button.closed-btn:hover {
  color: #03a791;
}
form {
  margin: 0;
}

/* File upload style */

.logo-upload .ant-upload.ant-upload-drag {
  background: #fff;
  border: 1px solid rgba(45, 59, 72, 0.08);
}
.logo-upload .ant-upload.ant-upload-drag p.ant-upload-text {
  margin: 0;
  color: #03a791;
  font-size: 10px;
  text-align: center;
  padding: 0 10px;
  line-height: normal;
}
.logo-upload .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  font-size: 21px;
}
.logo-upload .ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 5px;
}

.logo-upload label {
  margin-bottom: 5px;
  color: #222;
}

#user-edit {
  display: flex;
}
.delete-bnt {
  background: transparent !important;
  padding: 0;
  border: none;
  height: 16px;
  margin: 0 0 0 10px;
  text-align: center;
  width: 16px;
  line-height: normal;
  outline: none;
}
.delete-bnt:focus {
  outline: none;
}
.delete-bnt i {
  color: #03a791;
  font-size: 16px;
}
.delete-bnt i:hover {
  color: #008572;
}

/* add user table */

.org-user-table .tr .td:last-child {
  width: 75px;
  vertical-align: middle;
}

.ve-a-user-t .tr .td:last-child {
  width: 30px;
  vertical-align: middle;
}

.add-user-table tr th {
  font-size: 15px;
  font-weight: 400;
}
.add-user-table tr td {
  font-size: 14px;
  font-weight: 300;
}
.add-user-table tr th,
.add-user-table tr td {
  border-top: none;
  border-bottom: 1px solid #ddd;
  padding: 10px;
  vertical-align: top;
  transition: 0.5s all;
}

.add-user-table tr td:last-child {
  line-height: normal;
}

.add-user-table tr td input[type="text"],
.add-user-table tr td select,
.add-user-table tr td .ant-select-selection {
  height: 30px;
  padding: 5px;
  font-size: 13px;
  display: inline-block;
}
.add-user-table tr td .sf-form.co-code-txt .ant-select-selection {
  height: 30px;
}
.add-user-table tr td label {
  display: none;
}
.add-user-table tr td select {
  min-width: 100px;
}
.add-user-table
  tr
  td
  .add-n-role-d
  .ant-select-selection
  .ant-select-selection__rendered {
  height: 20px;
}
.add-user-table tr td .ant-select-selection .ant-select-selection__rendered {
  margin: 0 5px 0 0;
  line-height: normal;
  height: 30px;
  display: flex;
  align-items: center;
}
.add-user-table tr td .ant-select-selection .ant-select-arrow {
  right: 0;
}

.add-user-table tr:hover td {
  background: #f1f1f1;
}

/* ------ right Collapse ------------- */

.sf-collps-rt.ant-collapse {
  background: #fff;
  box-shadow: none;
  border: none;
}
.sf-collps-rt .view-text-value label {
  color: #222;
}

.sf-collps-rt.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  left: auto;
  right: 15px;
}
.sf-collps-rt.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  margin: 0;
  font-size: 15px;
  font-weight: 600;
  color: #222;
  border-bottom: 1px solid #eaebec;
  padding: 15px;
  background: transparent;
  border-radius: 4px 4px 0 0 !important;
}
.sf-collps-rt.ant-collapse .ant-collapse-content {
  border: none;
}
.sf-collps-rt .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}

.ant-calendar-body table tbody tr:nth-child(2n + 1) {
  background: transparent;
}
.ant-calendar-body table tbody tr {
  border: 0;
}
.ant-calendar table {
  margin: 0;
}
.ant-calendar-body table thead {
  border: 1px solid #ddd;
}

.sf-form .ant-select,
.sf-form .ant-calendar-picker {
  width: 100%;
}
.sf-form .ant-select {
  min-width: 100px;
}

.defaults-activity .ant-select {
  min-width: 150px;
}
/* .sf-form .ant-select span.ant-select-arrow {
  background: #03A791;
  color: #fff;
  padding: 8px;
  top: 0;
  border-radius: 0 4px 4px 0;
  right: 0;
  display: inline-block;
  margin: 0;
  border: 1px solid #03A791;
} */
.sf-form .ant-select-selection,
.sf-form .ant-calendar-picker-input {
  height: 35px;
  border-radius: 4px;
  background: #fff;
  border: 1px solid #eff0f1 !important;
  color: #222;
  font-weight: 400;
  width: 100%;
}
.sf-form .ant-calendar-picker-icon {
  right: auto;
  left: 12px;
}
.sf-form .ant-calendar-picker-input {
  padding-left: 30px;
}

#g-recaptcha {
  transform: scale(0.7);
}
.sf-g-captcah > div {
  display: flex;
  justify-content: center;
  height: 66px;
  align-items: flex-end;
}

.view-logo {
  background: #7b1fa2;
  border-radius: 50px;
  height: 55px;
  width: 55px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.view-logo img {
  /* height: 28px;
  width: auto; */
  height: 100%;
  width: 100%;
}
.view-text-value {
  line-height: normal;
  position: relative;
}
.view-text-value label {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 400;
}
.view-text-value span {
  font-size: 15px;
  display: block;
  font-weight: 300;
  color: rgba(45, 59, 72, 0.8);
}

/* add borders */
.lbr-1 {
  border-left: 1px solid #eaebec;
}
.vtrow-bx {
  margin-bottom: 15px;
}

/* Subscription Level */

.view-text-value .price-pm {
  display: inline-block;
  width: 100%;
  padding-top: 5px;
  color: #57626d;
  font-weight: 500;
}

.sf-badge {
  display: flex;
  justify-content: space-between;
}
.sf-badge .badge {
  color: #fff;
  background: #417505;
  font-weight: 400;
  font-size: 13px;
  border-radius: 50px;
  padding: 5px 10px 6px;
  display: inline-block;
  vertical-align: middle;
}

.sub-status-dtl {
  position: relative;
}
span.sub-pill {
  position: absolute;
  right: 0;
  height: 24px;
  border-radius: 100px;
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  width: 61px;
  line-height: 24px;
}
span.sub-pill.active {
  background: #417505;
}
span.sub-pill.inactive {
  background: rgba(45, 59, 72, 0.3);
}
.sub-amt-period {
  margin-top: 5px;
  display: flex;
}
.sub-amt-period span {
  font-size: 15px;
  color: rgba(45, 59, 72, 0.8);
  font-weight: 600;
  padding-right: 2px;
}
.sub-amt-period span:first-child::after {
  content: "/";
  font-size: 15px;
  font-weight: 700;
  padding-left: 2px;
}

/* edit logo image */
.add-new-file {
  display: flex;
  align-items: center;
  justify-content: left;
  border: 1px solid rgba(45, 59, 72, 0.08);
  padding: 10px;
  border-radius: 4px;
}
.add-new-file .view-logo {
  flex: 0 0 55px;
}
.edit-img-title {
  padding-left: 20px;
}

span.edit-image-logo {
  color: #3cb4a4;
  font-size: 12px;
  font-weight: 400;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.edit-img-title > span {
  display: flex;
  flex-direction: column;
}

.ant-upload-list-text {
  order: 1;
}
.add-new-file .edit-img-title .ant-upload-list-item-name {
  max-width: 150px;
  min-width: 100%;
  width: auto;
}
.add-new-file .edit-img-title .ant-upload-list-item {
  margin-top: 2px;
}

/* user management */

.bulk-import-files {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
}

.bulk-import-files .wnt-img {
  font-size: 64px;
  color: #e6e8e9;
  padding: 10px;
}
.blki-msg {
  padding-left: 10px;
}
.blki-msg h5 {
  font-weight: 600;
  font-size: 14px;
  color: #a1a7ad;
  margin-bottom: 2px;
}
.blki-msg span {
  color: #a1a7ad;
  font-weight: 500;
}
.blki-msg a {
  font-weight: 500;
}

.bulk-import-details {
  padding: 25px 10px 10px;
}

.bulk-import-details strong {
  font-size: 14px;
  font-weight: 600;
  color: #222;
}
ul.imp-sum-us {
  list-style: none;
  margin: 0;
  padding: 15px 0;
  line-height: normal;
}
ul.imp-sum-us li {
  padding: 0;
  margin: 0;
  font-size: 13px;
  line-height: normal;
}
ul.imp-sum-us li span {
  width: 100px;
  display: inline-block;
  padding-right: 5px;
  line-height: normal;
}
ul.imp-status-us {
  margin: 0;
  padding: 0;
  line-height: normal;
  list-style: none;
}
ul.imp-status-us li {
  padding: 0 15px 0 0;
  margin: 0;
  display: inline-block;
  font-size: 13px;
  font-weight: 400;
}
ul.imp-status-us li b {
  display: inline-block;
  font-weight: 400;
  width: 100%;
}

/* upload file  */

.upload-sfv-file .ant-upload.ant-upload-drag {
  border: 1px solid rgba(45, 59, 72, 0.08);
  background: #fff;
  border-radius: 2px;
  padding: 15px;
}

/* .upload-sfv-file .ant-upload.ant-upload-drag{
  border: none;
  background: transparent;
  padding: 0;
} */
.upload-sfv-file .ant-upload-text {
  border: 1px solid #03a791;
  display: inline-block;
  border-radius: 6px;
  padding: 4px 15px 5px;
  font-size: 12px !important;
  line-height: normal;
  color: #03a791 !important;
}

.upload-sfv-file .ant-upload.ant-upload-drag p.ant-upload-hint {
  font-size: 12px;
}

.upload-sfv-file .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  font-size: 35px;
}
.upload-sfv-file .ant-upload-drag-icon {
  margin-bottom: 0 !important;
  height: 48px;
}
.upload-sfv-file .ant-upload-drag-icon i.material-icons {
  font-size: 38px;
  color: #03a791;
}
.upload-sfv-file .ant-upload.ant-upload-drag p.ant-upload-text {
  margin: 0 0 8px;
}

.user-p-notxt img {
  height: 40px;
}

.user-p-notxt span {
  padding: 0 15px;
  font-size: 13px;
  line-height: normal;
}

.upload-sfv-file .ant-upload-list.ant-upload-list-text {
  display: none;
  visibility: hidden;
  opacity: 0;
}

.uplf-success,
.uplf-failed {
  font-size: 12px;
  color: #03a791;
  font-weight: 400;
}
.uplf-success {
  display: flex;
  align-items: center;
  justify-content: center;
}
.uplf-success i {
  background: #03a791;
  font-size: 7px;
  height: 10px;
  width: 10px;
  line-height: 13px;
  margin-right: 4px;
  display: inline-block;
  border-radius: 100px;
  color: #fff;
}
.uplf-failed {
  color: #d0021b;
}
.uplf-failed i {
  display: inline-block;
  padding-right: 4px;
}

.uplod-txt {
  font-size: 11px;
  font-weight: 400;
  display: inline-block;
  width: 100%;
}

.upload-sfv-file .ant-progress-status-success .ant-progress-bg {
  background-color: #03a791;
  height: 6px !important;
}

.file-up-msg {
  width: 60%;
  margin: 0 auto;
}

.upload-sfv-file .ant-progress-status-success .ant-progress-text {
  display: none;
}
.upload-sfv-file .ant-progress-outer {
  padding: 0;
  margin: 0;
}

.big-error {
  background: #fdf3f4;
  padding: 12px 15px;
  line-height: normal;
  color: #d0021b;
  font-weight: 400;
  font-size: 13px;
  margin-top: 15px;
  word-break: break-word;
}

/* checkbox groups ---- user management */

.sf-checkbox-b {
  margin: 0 0 15px;
  border-bottom: 1px solid #f2f3f4;
  padding-bottom: 15px;
}

.sf-checkbox-b h5 {
  font-weight: 400;
}

.more-role-prmison h4 {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
}

.sf-chkbx-group {
  display: flex;
  flex-wrap: wrap;
}

.sf-chkbx-group label.ant-checkbox-wrapper {
  font-size: 12px;
  font-weight: 500;
  display: flex;
  margin-bottom: 6px;
  line-height: 1;
  align-items: flex-start;
}

.sf-chkbx-group .cu-checkbox {
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 2px;
}

.sf-chkbx-group .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #03a791;
  width: 4px;
  height: 8px;
  top: 48%;
  left: 25%;
}

.sf-chkbx-group .ant-checkbox-wrapper + span,
.sf-chkbx-group .ant-checkbox + span {
  padding-left: 8px;
  padding-right: 4px;
}

.sf-chkbx-group .ant-checkbox-checked .ant-checkbox-inner {
  background: #fff;
  border-color: #979797;
}
.sf-chkbx-group .ant-checkbox-inner {
  height: 14px;
  width: 14px;
}

.error,
.error b {
  color: #d0021b;
}
.error-input {
  font-size: 11px;
  color: #d0021b;
  font-weight: 400;
  display: inline-block;
  width: 100%;
  padding-top: 5px;
}

.sf-row-bx {
  margin-bottom: 15px;
  border-bottom: 1px solid #fdf3f4;
  padding-bottom: 15px;
}
.sf-row-bx:last-child {
  padding-bottom: 0;
  border: 0;
  margin-bottom: 0;
}
.sf-row-bx .sf-chkbx-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* has scroll on button click */

.sf-service-list.has-scroll .ant-list-items {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 628px;
}

.sf-service-list.has-scroll .ant-list-items::-webkit-scrollbar {
  width: 2px;
  border-radius: 100px;
}
.sf-service-list.has-scroll .ant-list-items:hover::-webkit-scrollbar {
  width: 5px;
}
.sf-service-list.has-scroll .ant-list-items::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.sf-service-list.has-scroll .ant-list-items::-webkit-scrollbar-thumb {
  background: #3cb4a4;
}

/* switch style */
.sf-form .ant-switch {
  max-width: 44px;
}

/* readonly textbox */
.hide-txtbox,
.hide-txtbox:focus {
  border: none !important;
  box-shadow: none !important;
  background: #f1f1f1 !important;
  cursor: not-allowed;
}

/* not me button */
.outr-notme {
  position: relative;
}
.notmetxt {
  position: absolute;
  right: 10px;
  top: 12px;
  font-weight: 500;
  font-size: 12px;
  z-index: 9;
  background: #ff6c6c;
  line-height: normal;
  padding: 2px 8px 4px;
  margin: 0;
  border-radius: 5px;
  height: auto;
  color: #fff;
}

.rember-me {
  margin-top: 10px;
}

/* Roles permission buttons */
.sf-roles-group .ant-radio-group {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
.sf-roles-group .ant-radio-button-wrapper,
.sf-roles-group .ant-radio-button-wrapper:first-child {
  border: 0;
  border-radius: 0;
  width: 50%;
  padding: 0 6px 12px;
  height: auto;
}
span.rbp-btn {
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 5px 10px;
  align-items: center;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  height: 50px;
  position: relative;
}

.sf-roles-group .ant-radio-button-wrapper-checked span.rbp-btn:after {
  content: "\F00C";
  background: #03a791;
  width: 12px;
  color: #fff;
  font-size: 7px;
  height: 12px;
  border-radius: 50px;
  display: inline-block;
  position: absolute;
  right: -4px;
  text-align: center;
  top: -4px;
  font-family: FontAwesome;
}

.ant-radio-button-wrapper:not(:first-child)::before,
.ant-radio-button-wrapper-checked::before {
  contain: none;
  background: transparent !important;
  opacity: 0 !important;
}

.ant-radio-button-wrapper-checked {
  box-shadow: none !important;
}

.sf-roles-group .ant-radio-button-wrapper-checked span.rbp-btn {
  border: 1px solid #03a791;
}

.sf-roles-group .ant-radio-button-wrapper-checked:focus-within {
  outline: 0;
}

/* sidebar slide menu */

.sideBarOverlay::after {
  content: "";
  position: fixed;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.42);
  z-index: 99;
}

/* dashboard */

.dashb-heading {
  margin: 0 0 20px;
  font-weight: 600;
  font-size: 22px;
}

.sf-dashb h4 {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 5px;
}
.sf-dashb p {
  font-size: 15px;
  font-weight: 300;
  text-align: center;
  line-height: normal;
}

.sf-card.sf-dash-items {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.14);
  text-align: center;
  padding: 18px 20px;
  margin: 0 0 20px;
}
.sf-dash-items i {
  color: #03a791;
  font-size: 30px;
}
.sf-dash-items h5 {
  font-size: 16px;
  font-weight: 400;
  margin: 8px 0;
}
.sf-dash-items p,
.sf-modal-hd p {
  font-size: 14px;
  font-weight: 300;
  padding: 0;
}
.sf-card-head.sf-dashb {
  position: relative;
  padding: 0 20px;
  margin-bottom: 20px;
}
.sf-dashb button.closed-btn {
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.sf-modal-hd {
  text-align: center;
}
.sf-modal-hd h3 {
  margin-bottom: 2px;
}

.sf-intro-modal .ant-modal-footer {
  display: none;
}
.sf-intro-modal .ant-modal-close {
  background: transparent;
  color: #222 !important;
  padding: 0;
  line-height: normal;
  height: auto;
}
.sf-intro-modal .ant-modal-close .ant-modal-close-x {
  width: 40px;
  height: 40px;
  line-height: 40px;
}

/* profile */

.profile-tabs .ant-tabs-bar {
  border: 0;
  background: rgba(169, 169, 169, 0.45);
  margin-bottom: 30px;
  border-radius: 4px 4px 0 0;
}

.ant-tabs.ant-tabs-card.profile-tabs .ant-tabs-card-bar .ant-tabs-tab {
  background: transparent;
  border: 0;
}
.ant-tabs.ant-tabs-card.profile-tabs .ant-tabs-card-bar .ant-tabs-tab-active {
  background: #03a791;
  color: #fff;
  border-radius: 0;
}

.ant-tabs.ant-tabs-card.profile-tabs
  .ant-tabs-card-bar
  .ant-tabs-tab:first-child {
  border-radius: 4px 0 0 0;
}

.tab-wraper {
  padding: 0 25px 25px;
}

/* payment details */

.hascarddtl {
  position: relative;
}
.ewcard-icons {
  display: inline-block;
  position: absolute;
  z-index: 99;
  right: 5px;
  top: 33px;
}
.ewcard-icons span img {
  width: 30px;
  height: auto;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  opacity: 0.5;
}
.Visa .visa-card img,
.mastercard .master-card img,
.amex .amex-card img,
.dinner .dinner-card img {
  -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
  filter: grayscale(0%);
  opacity: 1;
}

.ewcard-icons span {
  background: #fbfbfb;
  padding: 0;
  margin-left: 3px;
  line-height: 31px;
  height: 25px;
  width: 40px;
  text-align: center;
  display: inline-block;
  border-radius: 4px;
}

.or-w-br {
  width: 100%;
  text-align: center;
  position: relative;
  margin: 15px 0;
  text-transform: uppercase;
}
.or-w-br:after {
  position: absolute;
  left: 0;
  top: 10px;
  content: "";
  width: 100%;
  height: 1px;
  background: #d8d8d8;
}
.or-w-br span {
  position: relative;
  z-index: 2;
  background: #fff;
  padding: 3px 10px;
}
.or-swtpay-bnt button.bnt {
  width: 100%;
}

/* profile pages */

.user-profile-card {
  background: #4a4a4a;
  border-radius: 4px 4px 0 0;
  text-align: center;
  padding: 10px;
}
.profile-pic {
  height: 80px;
  margin: 0 auto;
  width: 80px;
  font-size: 30px;
  color: #fff;
  line-height: 75px;
  text-align: center;
  border-radius: 50px;
  border: 3px solid #fff;
  position: relative;
}
.profile-pic img {
  height: 80px;
  width: 80px;
  border-radius: 50px;
}

.user-title {
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  text-align: center;
  line-height: normal;
  margin: 10px 0 0;
}
.user-title span {
  display: inline-block;
  width: 100%;
  font-size: 14px;
  font-weight: 300;
}

.upload-pic {
  position: absolute;
  right: -4px;
  bottom: 30px;
  width: 25px;
  height: 25px;
}

.upload-pic button.ant-btn {
  background: #fff;
  border: 0;
  width: 25px;
  height: 25px;
  font-size: 12px;
  text-align: center;
  color: #03a791;
  padding: 0;
  border-radius: 50px;
}
.upload-pic button.ant-btn i.fa {
  color: #03a791;
}

.usr-pro-footer {
  background: rgba(169, 165, 170, 0.45);
}

.usr-pro-body {
  padding: 20px;
}
.usr-pro-body h3 {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}
.usr-pro-body p {
  font-weight: 300;
  margin: 0;
}
.usr-pro-footer {
  background: rgba(169, 165, 170, 0.45);
  border-radius: 0 0 4px 4px;
  display: flex;
  justify-content: space-around;
  text-align: center;
  align-items: center;
}
.pro-status {
  width: 100%;
  border-right: 1px solid #d8d8d8;
  padding: 10px;
  text-align: left;
}
.pro-status:last-child {
  border-right: 0;
}
.pro-status strong {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 400;
}
.pro-status span {
  display: inline-block;
  width: 100%;
  text-align: left;
  font-size: 13px;
  font-weight: 300;
}
.ovhdn {
  overflow: hidden;
}

.dob-dropd {
  display: flex;
  justify-content: space-between;
  align-self: flex-start;
}
.dob-dropd .sf-select {
  flex: auto;
  padding: 0 5px;
}
.dob-dropd .sf-select label {
  display: none;
}
.dob-dropd .sf-select:first-child {
  padding-left: 0;
}
.dob-dropd .sf-select:last-child {
  padding-right: 0;
}
.sf-form .dob-dropd .ant-select {
  min-width: 100%;
}

.select-wibg .ant-select-arrow {
  background: #03a791;
  padding: 10px;
  line-height: 20px;
  color: #fff;
  top: 0;
  height: 100%;
  right: 0;
  width: 30px;
  margin: 0;
  border-radius: 0 4px 4px 0;
}
.drop-mw .ant-select-dropdown,
.ant-select-dropdown {
  min-width: 80px;
  max-width: 100%;
}
.drop-mw .ant-select-dropdown-menu-item {
  text-overflow: unset;
}

.sf-table,
.sf-table tr th,
.sf-table tr td {
  border: 0;
  padding: 2px 4px;
}
.sf-table {
  margin: 0;
}

button.exp-bnt {
  border-radius: 4px;
  color: #fff;
  height: 35px;
  width: 35px;
  text-align: center;
  padding: 0;
  border: 0;
  transition: 0.5s all;
}

button.exp-bnt.delete {
  background: #d0021b;
}
button.exp-bnt.add {
  background: #03a791;
}
button.exp-bnt.delete:hover,
button.exp-bnt.add:hover {
  background: #222;
}

.w-30 {
  width: 30%;
}
.w-5px {
  width: 50px;
}

.exp-details {
  margin: 5px 0 0;
  padding: 0;
}
.exp-details li {
  list-style: none;
  padding: 0 0 5px;
  margin: 0 0 5px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
}
.exp-details li:last-child {
  border-bottom: 0;
  padding: 0;
  margin: 0;
}
.exp-details li span {
  font-size: 14px;
  font-weight: 300;
}

/* sf view details grid  */

.data-v-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}
.data-v-col {
  border-right: 1px solid rgba(45, 59, 72, 0.08);
  margin-bottom: 15px;
}
.data-v-col:last-child {
  border: 0;
}
.data-v-col .view-text-value {
  line-height: normal;
  padding: 0;
}
.data-v-row .data-v-col .view-text-value {
  padding: 0 15px;
}

.col.org-logo-col {
  flex-basis: 100px;
  flex-grow: 1;
  max-width: 100px;
}

/* invite user table */

.invite-user table tr th {
  background: transparent;
  border-bottom: 1px solid #e8e8e8;
  font-weight: 600;
}
.invite-user table thead {
  border: none;
}
.invite-user table .ant-table-thead > tr > th,
.invite-user table .ant-table-tbody > tr > td {
  padding: 10px;
}
.ant-table-pagination.ant-pagination {
  margin: 20px 0 0;
}
.ant-pagination-item {
  padding: 0;
}

.bnt-simple {
  background: transparent;
  height: auto;
  line-height: normal;
  padding: 0;
  margin: 0;
  color: #03a791 !important;
  font-weight: 400;
  font-size: 12px;
}
.bnt-simple:hover {
  background: transparent;
  text-decoration: underline;
}

.sf-row-bx.role-per-lists .sf-chkbx-group {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.sf-row-bx .sf-chkbx-group .permission-items .cu-checkbox {
  flex: auto;
  max-width: 100%;
}
.sf-row-bx .sf-chkbx-group .permission-items {
  flex: auto;
  min-width: 30%;
  max-width: 33.33%;
}

.sf-row-bx.edit-pr-items .sf-chkbx-group {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.edit-pr-items .edit-pr-list {
  flex: 0 0 50%;
  width: 50%;
  margin-bottom: 2px;
  padding-right: 5px;
}
.view-perm-items {
  margin-bottom: 5px;
  border-bottom: 1px solid rgba(45, 59, 72, 0.04);
  padding-bottom: 5px;
}

.view-perm-items .sf-chkbx-group {
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.view-perm-items .sf-chkbx-group span {
  padding-right: 20px;
  margin: 4px 0;
}
/* pagination */
ul.ant-pagination.sf-pagination {
  margin: 30px 0 10px;
  display: flex;
  justify-content: flex-end;
}
ul.ant-pagination > li {
  padding: 0;
}
ul.ant-pagination .ant-pagination-item-active {
  background: #03a791;
  color: #fff;
}

/* Edit Subscription Modules */

.add-sub-mod .ant-checkbox-wrapper {
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 5px 10px;
  align-items: center;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  height: 50px;
  position: relative;
}
.add-sub-mod .ant-checkbox-wrapper .ant-checkbox {
  position: absolute;
  right: -6px;
  top: -6px;
  display: none;
  opacity: 0;
  transition: 0.5s all;
}
.add-sub-mod .ant-checkbox-wrapper .ant-checkbox-inner {
  border-radius: 50px;
}
.add-sub-mod .ant-checkbox-wrapper-checked {
  border: 1px solid #03a791;
}
.add-sub-mod .ant-checkbox-wrapper-checked span {
  color: #03a791;
}
.add-sub-mod .ant-checkbox-wrapper-checked .ant-checkbox {
  display: block;
  opacity: 1;
}
.add-sub-mod .ant-checkbox-wrapper + span,
.add-sub-mod .ant-checkbox + span {
  padding: 0;
  font-size: 13px;
  white-space: pre-wrap;
  word-break: break-word;
}
.sf-roles-group.add-sub-mod {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
}
.add-sub-mod .cu-checkbox {
  flex: 0 0 50%;
  padding: 0 10px 10px 0;
  max-width: 50%;
  margin: 0;
}
.sub-3.add-sub-mod .cu-checkbox {
  flex: 0 0 33.33%;
  max-width: 33.33%;
}

.sub-modl-items {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
}
.sub-items {
  width: 33.33%;
  flex: 0 0 33.33%;
  padding: 0 0 10px 10px;
}
.sub-items span {
  font-size: 14px;
  font-weight: 400;
  padding: 8px 5px;
  border: 1px solid #efefef;
  text-align: center;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.04);
  display: block;
}
.navbar-bnt {
  color: rgba(45, 59, 72, 0.3);
}
.navbar-bnt i {
  font-size: 20px;
}
.navbar-bnt:hover {
  color: #03a791;
}

.ant-list-item-meta-avatar label {
  background: #7b1fa2;
  width: 40px;
  height: 40px;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  border-radius: 50px;
  padding: 5px;
  line-height: 32px;
  text-align: center;
}

.itm-amount-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.itm-amount-date span {
  font-size: 11px;
}

/* update payment info */
.sf-exp-dates {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: -10px;
}
.sf-exp-dates .sf-form {
  flex: 1;
  padding-right: 0;
}
.sf-exp-dates .card-exp-drop .sf-form {
  flex: none;
  padding: 0;
}
.sf-exp-dates .card-exp-drop,
.sf-exp-dates .ccv-cvc {
  flex: 0 0 50%;
  padding-right: 10px;
  width: 50%;
  position: relative;
}
.sf-form .exp-slt-box .ant-select {
  min-width: 100%;
}
.exp-slt-box {
  display: flex;
}
.exp-slt-box .sf-select.select-wibg {
  flex: 0 0 50%;
  width: 50%;
}
.sf-form .exp-slt-box .ant-select-selection {
  border-radius: 4px 0 0 4px;
}
.sf-form .exp-slt-box .sf-select.select-wibg:last-child .ant-select-selection {
  border-radius: 0 4px 4px 0;
  border-left: 0 !important;
}
.ccv-cvc .sf-form input[type="text"] {
  line-height: 34px;
}
.sf-form .exp-slt-box .ant-select-selection__rendered {
  line-height: 34px;
}
.sf-form .exp-slt-box .ant-select-selection-selected-value {
  padding-right: 0;
}
.cvv-tooltip {
  position: absolute;
  top: 4px;
  right: 10px;
}
.cvv-tooltip .normal-bnt {
  color: rgba(45, 59, 72, 0.45);
}
.cvv-tooltip .normal-bnt i {
  font-size: 14px;
}
#cvvTooltip .ant-tooltip-inner {
  background: #fff;
}
#cvvTooltip .ant-tooltip-inner img {
  width: 100px;
}
#cvvTooltip .ant-tooltip-placement-left .ant-tooltip-arrow {
  border-top-color: #fff;
  border-right-color: #fff;
}
.card-exp-drop .select-wibg .ant-select-arrow,
.card-exp-drop .sf-select.select-wibg label {
  display: none;
}
/* end */

.rap-cell-table tr td:last-child {
  word-break: break-all;
}

/* full screen center card */

.card-expands {
  display: flex;
  justify-content: flex-end;
}
.exapnd-bnt {
  margin-bottom: 8px;
  font-size: 15px;
  color: rgba(45, 59, 72, 0.3);
}
.exapnd-bnt:hover {
  color: #008572;
}

.main-container.po-relative {
  position: relative;
}
.sf-card-wrap.fullscreen {
  position: absolute;
  left: 18px;
  top: 20px;
  z-index: 99;
  margin: 0 15px;
  width: calc(100% - 48px);
  min-height: 100%;
}
.po-relative .col-lg-1,
.po-relative .col-lg-2,
.po-relative .col-lg-3,
.po-relative .col-lg-4,
.po-relative .col-lg-5,
.po-relative .col-lg-6,
.po-relative .col-lg-7,
.po-relative .col-lg-8,
.po-relative .col-lg-9,
.po-relative .col-lg-10,
.po-relative .col-lg-11,
.po-relative .col-lg-12,
.po-relative .col-md-1,
.po-relative .col-md-2,
.po-relative .col-md-3,
.po-relative .col-md-4,
.po-relative .col-md-5,
.po-relative .col-md-6,
.po-relative .col-md-7,
.po-relative .col-md-8,
.po-relative .col-md-9,
.po-relative .col-md-10,
.po-relative .col-md-11,
.po-relative .col-md-12,
.po-relative .col-sm-1,
.po-relative .col-sm-2,
.po-relative .col-sm-3,
.po-relative .col-sm-4,
.po-relative .col-sm-5,
.po-relative .col-sm-6,
.po-relative .col-sm-7,
.po-relative .col-sm-8,
.po-relative .col-sm-9,
.po-relative .col-sm-10,
.po-relative .col-sm-11,
.po-relative .col-sm-12,
.po-relative .col-xs-1,
.po-relative .col-xs-2,
.po-relative .col-xs-3,
.po-relative .col-xs-4,
.po-relative .col-xs-5,
.po-relative .col-xs-6,
.po-relative .col-xs-7,
.po-relative .col-xs-8,
.po-relative .col-xs-9,
.po-relative .col-xs-10,
.po-relative .col-xs-11,
.po-relative .col-xs-12 {
  position: static;
}
.zoom-save-bnt {
  margin-top: 15px;
  display: none;
}
.zoom-save-bnt.show-bnt {
  display: block;
}

/* service farm custom div table */

.sf-c-table {
  display: table;
  width: 100%;
  margin-bottom: 1rem;
}
.sf-c-table .tr {
  display: table-row;
}
.sf-c-table .td,
.sf-c-table .th {
  display: table-cell;
  border-bottom: 1px solid rgba(45, 59, 72, 0.1);
  padding: 10px;
  vertical-align: top;
  word-break: break-all;
}
.sf-c-table .td {
  font-size: 14px;
  font-weight: 300;
  transition: 0.5s all;
}
.sf-c-table .tr:hover .td {
  background: rgba(45, 59, 72, 0.04);
}
.sf-c-table .th {
  font-size: 15px;
  font-weight: 400;
}

.sf-c-table .sf-form label {
  display: none;
}

.view-logo b {
  color: #fff;
}

.s-n-bnt.btn-hs-icon {
  position: relative;
  top: 1px;
}

/* react scrollArea */
.scrollarea.sf-scroll {
  min-height: auto;
  max-height: 500px;
  margin-bottom: 15px;
}
.scrollarea .scrollbar-container.vertical {
  width: 6px;
  border-radius: 100px;
}
.scrollarea .scrollbar-container.vertical .scrollbar {
  width: 4px;
  border-radius: 100px;
}
.scrollarea.sf-scroll.h750 {
  max-height: 650px;
}

/* menu scroll */
.scrollarea.sf-scroll.menu-scroll {
  max-height: inherit;
}
.scrollarea.menu-scroll .scrollbar-container.vertical {
  width: 4px;
  border-radius: 100px;
  background: #222;
}
.scrollarea.menu-scroll .scrollbar-container.vertical .scrollbar {
  width: 2px;
  border-radius: 100px;
  background: #fff;
}
.scrollarea.sf-scroll.menu-scroll.h800 {
  max-height: 800px;
}
/* menu scroll end */

.scrollarea.sf-scroll-width {
  width: 100%;
}
.scrollarea.sf-scroll-width .scrollarea-content {
  min-width: 600px;
  margin-bottom: 20px;
}
.scrollarea .scrollbar-container.horizontal {
  height: 6px;
  border-radius: 100px;
}
.scrollarea .scrollbar-container.horizontal .scrollbar {
  height: 4px;
  border-radius: 100px;
}
.scrollarea.sf-scroll-width.mw6 .scrollarea-content {
  min-width: 600px;
}

/* all search box scrollbar */

.sf-search-body .scrollarea.sf-scroll {
  max-height: none;
  margin-bottom: 0;
}
.sf-search-body .search-lists-bar .scrollarea.sf-scroll {
  max-height: 610px;
}

/* can be remove the style in future */

.sf-st-item .iconbx.key-rtic {
  line-height: 35px;
}
.key-rtic img {
  width: 16px;
}

.hlight-n-user td {
  background: rgba(3, 167, 145, 0.1);
}

/* Mobile number with country code */

.co-code-mobile-no {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.sf-form.co-code-txt .ant-select {
  /* min-width:auto; */
  min-width: 100%;
}
.sf-form.co-code-txt .ant-select-selection {
  border-radius: 4px 0 0 4px;
  border: 0 !important;
  background: #03a791;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 6px;
}
fieldset.sf-form.co-code-txt {
  position: relative;
  min-width: 40px;
  max-width: 100px;
}

.sf-form.co-code-txt .select-wibg .ant-select-arrow {
  background: transparent;
  color: #fff;
  border-radius: 0;
  width: 10px;
  position: static;
  padding: 0;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 2px;
}
fieldset.sf-form.mobile-ntxt {
  width: calc(100% - 100px);
  flex: auto;
  min-width: 100px;
  max-width: 100%;
}
.sf-form.co-code-txt .ant-select-selection-selected-value {
  color: #fff;
  padding: 0;
  overflow: unset;
  text-overflow: unset;
  min-width: 16px;
  max-width: 100px;
  float: none;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 13px;
}
.sf-form.mobile-ntxt input[type="text"] {
  border-radius: 0 4px 4px 0;
  border-left: 0;
}
.co-code-txt .ant-select-selection__rendered {
  line-height: 34px;
  margin: 0;
  height: 34px;
}
.co-code-txt .ant-select-search--inline {
  top: 0;
}

.co-code-txt .ant-select-dropdown-menu,
.ant-select-dropdown-menu {
  overflow-x: hidden;
  overflow-y: scroll;
}
.co-code-txt .ant-select-dropdown-menu::-webkit-scrollbar,
.ant-select-dropdown-menu::-webkit-scrollbar {
  width: 4px;
  border-radius: 100px;
}
.co-code-txt .ant-select-dropdown-menu::-webkit-scrollbar-track,
.ant-select-dropdown-menu::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.co-code-txt .ant-select-dropdown-menu::-webkit-scrollbar-thumb,
.ant-select-dropdown-menu::-webkit-scrollbar-thumb {
  background: rgba(45, 59, 72, 0.1);
}
.co-code-txt .ant-select-dropdown-menu:hover::-webkit-scrollbar-thumb,
.ant-select-dropdown-menu:hover::-webkit-scrollbar-thumb {
  background: rgba(45, 59, 72, 0.2);
}
.ant-select-dropdown-menu-item {
  text-overflow: unset;
}

.sf-scroll-width .ant-select-selection__rendered {
  line-height: 34px;
}

/* end */

/* search box */
.show-search-box {
  display: flex;
  background: #fff;
  border: 1px solid rgba(45, 59, 72, 0.2);
  border-radius: 50px;
  height: 35px;
  padding: 0 10px;
}
.page-search-box input.toggle-textbox,
.page-search-box input.toggle-textbox:active,
.page-search-box input.toggle-textbox:focus {
  border: 0;
  outline: none;
  background: transparent;
  min-height: 100%;
  padding: 5px;
  line-height: normal;
  margin-right: 10px;
  color: rgba(45, 59, 72, 0.5);
  font-size: 14px;
}
.page-search-box {
  display: flex;
}
.page-search-box button.normal-bnt {
  margin: 0 10px;
}
.sett-badge.search-badge {
  cursor: default;
}

.sf-chkbx-group.chk-bullet {
  padding: 2px 10px;
}
.chk-bullet > span {
  color: rgba(0, 0, 0, 0.65);
  font-size: 12px;
  font-weight: 500;
  padding: 0 10px 12px;
  flex: 0 0 50%;
  position: relative;
}
.chk-bullet > span:before {
  content: "fiber_manual_record";
  font-family: "Material Icons";
  color: rgba(0, 0, 0, 0.5);
  font-size: 6px;
  position: absolute;
  left: 0;
  top: 5px;
}
#confirmPopPo,
#user-edit {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
/* .co-code-txt .ant-select-selection__rendered {
  line-height: 35px;
} */
/* .ad-nusr-txt .sf-form input[type="text"] {
  min-width: 100px;
  max-width: 120px;
} */
.sf-searchbar.closed .sf-card-footer {
  padding: 10px 5px;
  align-items: center !important;
}
.closed.sf-searchbar .ant-empty-image img {
  width: 44px;
}
.closed.sf-searchbar .ant-list-empty-text {
  padding: 16px 10px;
}
.sf-searchbar.closed .sf-card-head {
  padding: 0;
  display: flex;
  justify-content: center;
}
.sf-searchbar.closed .sf-card-head .ant-select-auto-complete {
  width: auto;
}
.sf-searchbar.closed .sf-service-list.ant-list-split .ant-list-item a {
  padding: 5px 12px;
  height: 60px;
}
.sf-service-list.ant-list-split .ant-list-item a {
  height: 62px;
}

/* page scrollbar design */
.sf-ps-scroll-content {
  min-width: 600px;
  padding-bottom: 20px;
}
.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  height: 9px;
  border-radius: 0;
  opacity: 0.6;
}
.sf-ps-scroll .ps__rail-x .ps__thumb-x,
.sf-ps-scroll .ps__rail-y .ps__thumb-y {
  border-radius: 20px;
  height: 6px !important;
  bottom: 1px;
}

/* remove arrow in number textbox */
.remove-num-arrows input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.remove-num-arrows input[type="number"] {
  -moz-appearance: textfield;
}
/* user permission collapse list */

.ant-collapse.user-permis-list,
.ant-collapse.user-permis-list div {
  border: 0;
  background: transparent;
}
.ant-collapse.user-permis-list .ant-collapse-item {
  margin: 0 0 10px;
}
.ant-collapse.user-permis-list .ant-collapse-header {
  padding: 0 40px 10px 0;
  border-bottom: 1px solid rgba(45, 59, 72, 0.08);
  color: #222;
  font-weight: 500;
}
.ant-collapse.user-permis-list
  .ant-collapse-item.ant-collapse-item-active
  .ant-collapse-header {
  color: #222;
}
.user-permis-list.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  color: rgba(45, 59, 72, 0.45);
}
.user-permis-list.ant-collapse .ant-collapse-content-box {
  padding: 15px 0 0 0;
  border-bottom: 1px solid rgba(45, 59, 72, 0.08);
}
.user-permis-list .ant-collapse-content-box .scrollarea.sf-scroll {
  margin-bottom: 0;
}

/* clip images */
.ant-list.sf-service-list .ant-avatar > img,
.usr-profile .ant-avatar-image img,
.jdp-head img,
.view-logo img {
  object-fit: cover;
}
/* list search spiner */
.list-search-spin .ant-input-suffix {
  height: 38px;
  width: 38px;
}
.list-search-spin .ant-input-suffix .ant-spin-dot {
  top: -3px;
  left: -3px;
}

/* search list spin */

.sf-search-body {
  position: relative;
}
.search-list-spin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.68);
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sf-form.lsico .ant-calendar-picker-input {
  padding-left: 10px;
  padding-right: 25px;
}
.sf-search-body.add-height .search-lists-bar .scrollarea.sf-scroll {
  max-height: 666px;
}

.sf-service-list .itm-amount-date span {
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

/* add currency symbol */
.w-currency-symbl > div > div {
  position: relative;
}
.w-currency-symbl > div > div:after {
  content: "$";
  position: absolute;
  left: 10px;
  top: 7px;
  color: #444444;
  text-align: center;
  font-weight: 400;
}
.w-currency-symbl.sf-form input[type="text"] {
  padding-left: 22px;
  /* added Service Industries in org  */
}
.a-sr-agent .s-n-bnt {
  display: none;
}
.sf-card-inner {
  padding: 15px;
  border: 1px solid rgba(45, 59, 72, 0.08);
  border-radius: 4px;
  margin-bottom: 20px;
  position: relative;
}
.doc-action-bnt {
  position: absolute;
  right: 15px;
  top: 15px;
  display: flex;
  z-index: 99;
}
.add-line-bnt i {
  font-size: 18px;
  padding-right: 3px;
}
.add-line-bnt {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  display: flex;
  align-items: center;
}
.add-sfs-agent {
  margin: 15px 0 0;
}
.sf-card-inn-bg {
  padding: 15px;
  background: rgba(45, 59, 72, 0.04);
  border-radius: 4px;
  margin-bottom: 20px;
  position: relative;
}
.mor-sub-cat {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 0 7px 0;
}
table.sub-cat-table {
  width: 100%;
}
.sub-cat-table tr th {
  padding: 10px 0 0;
}
table.sub-cat-table tr th:first-child,
table.sub-cat-table tr td:first-child {
  padding-right: 5px;
}
table.sub-cat-table tr th:last-child,
table.sub-cat-table tr td:last-child {
  padding-left: 5px;
}
table.sub-cat-table tr td:first-child .mor-sub-cat {
  padding-left: 0;
}
.mor-sub-cat .sf-form.form-group {
  margin: 0;
  padding: 0 8px 0 0;
  flex: 1 1;
}
.sf-card-inn-bg .form-group.sf-form {
  margin: 0;
}
.wc-6 {
  width: 50%;
}
.mor-sub-cat button.exp-bnt {
  flex: 0 0 34px;
  border-radius: 4px;
  color: #fff;
  height: 34px;
  width: 34px;
  text-align: center;
  line-height: 34px;
  padding: 0;
  border: 0;
}
.mor-sub-cat label {
  display: none;
}
.doc-action-bnt button {
  font-size: 14px;
  margin-left: 10px;
}
.auto-comp .ant-select-auto-complete.ant-select .ant-input {
  height: 35px !important;
  padding-right: 10px;
  background: #fff;
}
.auto-comp .ant-select-auto-complete.ant-select .ant-select-selection--single {
  border: 0 !important;
}
.ant-select-auto-complete.ant-select .ant-select-selection__rendered {
  line-height: 34px;
}

.edit-si-box {
  position: relative;
}

.edit-si-box .doc-action-bnt {
  top: 0;
  right: 0;
}
.sa-cat-heading {
  position: relative;
  padding: 15px 0 15px 45px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 15px;
}
.sa-sub-cat {
  display: flex;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(45, 59, 72, 0.1);
}
.sa-sub-cat:last-child {
  border: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}
.sa-sub-cat .view-text-value {
  flex: 0 0 70%;
}
.cat-view-value.view-text-value {
  padding-right: 15px;
  margin-right: 15px;
  border-right: 1px solid rgba(45, 59, 72, 0.1);
  flex: 0 0 30%;
}
.sa-cat-pleft {
  padding-left: 45px;
}
.dragde-bnt {
  display: flex;
  flex-direction: column;
  line-height: normal;
  position: absolute;
  left: 15px;
  top: 16px;
  cursor: move;
  z-index: 9999;
}
.dragde-bnt i {
  height: 3.5px;
  color: rgba(45, 59, 72, 0.24);
}

/* new style for role management */
.role-sub-access {
  margin-left: 10px;
  border-top: 1px solid #efefef;
}
.role-sub-access .sf-chkbx-group {
  margin-left: 10px;
}
.role-sub-access h5 {
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.sf-form.role-jb-ccld-tbl .ant-select-selection {
  min-height: 35px;
  height: auto;
}
/* SWSM Management  */
.swms-mgnt-nav {
  display: inline-block;
  width: 100%;
}
.sm-nav-items {
  border-bottom: 1px solid rgba(45, 59, 72, 0.06);
  border-top: 1px solid transparent;
  padding: 16px 15px;
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  transition: 0.5s all;
  color: #8e8e8e;
  font-size: 15px;
  font-weight: 500;
}
.sm-nav-items i {
  padding: 0 10px 0 0;
  font-size: 16px;
}

/* add swms  */
.sf-c-table.swms-view-table .td,
.sf-c-table.swms-view-table .th {
  word-break: unset;
}
.scrollarea.swms-table-scroll {
  padding-bottom: 5px;
  margin-bottom: 10px;
}

/* Drawer */
.antt-form .btn-hs-icon {
  padding-left: 10px;
}
.antt-form .btn-hs-icon .bnt {
  width: 100%;
  height: 32px;
  font-size: 15px;
  font-weight: bold;
  min-width: 100px;
}
.toolbox-talk-drawer .ant-drawer-header-no-title {
  display: none;
}
.toolbox-talk-drawer .ant-drawer-body {
  padding: 15px;
}
.tbox-table .tr .td:last-child {
  width: 20px;
}
.tbox-table .tr .td .delete-bnt {
  margin: 0;
}
.tbox-table .sf-form textarea {
  height: 40px;
}
.tbox-table.sf-c-table .td,
.tbox-table.sf-c-table .th {
  border: 0;
}
.tbox-table.sf-c-table .td {
  padding-top: 2px;
  padding-bottom: 2px;
  background: transparent !important;
}
.tbox-table .tr .th:nth-child(3) {
  width: 40%;
}

/* auto complete search */
.client-name {
  position: relative;
  width: 100%;
}
.tbox-table .ant-select-auto-complete.ant-select .ant-input {
  padding-right: 35px;
}
.tbox-table .select-wibg .ant-select-arrow {
  line-height: 14px;
}
.srch-icons {
  position: absolute;
  right: 36px;
  top: 9px;
  font-size: 12px;
  color: rgba(169, 165, 170, 0.45);
}

.tool-talk-attach .logo-upload .ant-upload.ant-upload-drag p.ant-upload-text {
  font-size: 11px;
  font-weight: 400;
}
.add-line-bnt {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 11px;
  display: flex;
  align-items: center;
}
.add-line-bnt i {
  font-size: 16px;
  padding-right: 2px;
}
.swms-table-scroll {
  max-width: 1000px;
  padding-bottom: 20px;
}
/* forgot user name */

.forgot-co-code {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
}
.forgot-co-code .fldset-box {
  margin: 0;
  flex: auto;
}
.forgot-co-code fieldset.sf-form.co-code-txt {
  min-width: 40px;
  max-width: 100px;
}
.forgot-co-code .userTxtbox input {
  padding: 0 10px;
  border-left: 0;
  border-radius: 0 4px 4px 0;
}
.forgot-co-code .sf-form.co-code-txt .ant-select-selection {
  height: 45px;
  justify-content: space-between;
}
.forgot-co-code .co-code-txt .ant-select-selection__rendered {
  height: 45px;
}
.forgot-co-code
  .sf-form.co-code-txt
  .select-wibg
  .ant-select-arrow
  .ant-select-arrow-icon {
  font-size: 16px;
  height: 15px;
  width: 15px;
}
.forgot-co-code .sf-form.co-code-txt .select-wibg .ant-select-arrow {
  padding: 0 10px;
}
.forgot-co-code .sf-form.co-code-txt .ant-select-selection-selected-value {
  font-size: 18px;
  font-weight: 400;
  min-width: 100%;
  max-width: 100%;
}
.co-code-txt .ant-select-search--inline .ant-select-search__field {
  color: #fff;
}
.calender-heading .ant-select-selection--single .ant-select-selection__rendered,
.calender-heading li.ant-select-dropdown-menu-item {
  text-transform: capitalize;
}
.ant-list.sf-service-list .ant-avatar > img,
.usr-profile .ant-avatar-image img,
.jdp-head img {
  object-fit: cover;
}
.card-exp-drop .ant-select-selection__placeholder,
.card-exp-drop .ant-select-search__field__placeholder {
  right: 0px;
}
.update-signle-tool-type .ant-select-selection {
  max-height: 100%;
  height: 100%;
  max-width: 400px;
  min-width: 170px;
}

/* upload chemicals */
.chemicals-afbf-pic .loadf-txt {
  border: 1px solid rgba(45, 59, 72, 0.06);
  border-radius: 4px;
  padding: 5px;
  display: flex;
  align-items: center;
  color: #03a791;
  line-height: normal;
}
.chemicals-afbf-pic .loadf-txt .ant-upload-text {
  padding-left: 5px;
  font-size: 12px;
  font-weight: 600;
  color: #03a791;
}
.chemicals-afbf-pic .loadf-txt .material-icons {
  font-size: 14px;
}
.chemicals-afbf-pic .loadf-txt:hover {
  border: 1px solid #03a791;
}

.chemicals-afbf-pic .ant-upload-list-item-name {
  max-width: 150px;
}

/* swms editor box */

.dynamic-html-cnt strong {
  font-weight: 600;
  display: inline-block;
}
.dynamic-html-cnt ul {
  list-style: disc;
  padding-left: 15px;
}
.dynamic-html-cnt ul li {
  list-style: disc;
}
.dynamic-html-cnt em {
  font-style: italic;
}
.dynamic-html-cnt ol {
  padding-left: 15px;
  list-style: decimal;
}
.dynamic-html-add .rdw-editor-main {
  border: 1px solid #f1f1f1;
  border-top: 0;
  padding: 0 10px;
  border-radius: 0 0 2px 2px;
  background: #fff;
}
.dynamic-html-add .rdw-editor-toolbar {
  background: #fcfcfc;
  margin-bottom: 0;
  border-radius: 2px 2px 0 0;
}
.dynamic-html-add .rdw-editor-main pre {
  margin-bottom: 10px;
}
.dynamic-html-cnt blockquote {
  quotes: "'" "'";
}
.dynamic-html-cnt p {
  padding-bottom: 5px;
}
.dynamic-html-cnt h1 {
  font-size: 2rem;
  font-weight: 600;
}
.dynamic-html-cnt h2 {
  font-size: 1.75rem;
  font-weight: 600;
}
.dynamic-html-cnt h3 {
  font-size: 1.5rem;
  font-weight: 600;
}
.dynamic-html-cnt h4 {
  font-size: 1.25rem;
  font-weight: 600;
}
.dynamic-html-cnt h5 {
  font-size: 1rem;
  font-weight: 600;
}
.dynamic-html-cnt h6 {
  font-size: 0.75rem;
  font-weight: 600;
}

/* ------- end ------------- */

.cust-expand-txt-area textarea {
  resize: auto;
  overflow: hidden;
}

/* induction Management Module */
.up-course-files .ant-upload.ant-upload-drag {
  width: 50%;
}
.logo-upload.up-course-files label {
  margin-bottom: 10px;
}
.sf-roles-group.so-check-role {
  display: flex;
  flex-wrap: wrap;
}
.trtc-minut input {
  width: 50% !important;
}
.trtc-minut {
  position: relative;
}
.trtc-mt {
  position: absolute;
  right: 0;
  top: 28px;
  display: flex;
  color: #a7a7a7;
  align-items: center;
}
.trtc-mt button.bnt {
  width: 30px;
  padding: 0;
  flex: 0 0 30px;
  height: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  border-color: rgba(45, 59, 72, 0.06);
}
.trtc-mt button.bnt i {
  margin: 0;
  padding: 0;
  color: rgba(45, 59, 72, 0.5);
  font-size: 15px;
}

/* quiz  */

.quiz-tabs-wrap .ant-tabs {
  overflow: unset;
}
.quiz-tabs-wrap .ant-tabs-bar {
  border: 0;
  margin: 0 0 15px 0;
}
.quiz-tabs-wrap .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  border: 1px solid #03a791;
  border-radius: 4px;
  color: #03a791;
  padding: 0 10px;
  background: #fff;
  height: 35px;
  line-height: 35px;
  width: 50%;
  font-size: 13px;
  margin-right: 5px;
  text-align: center;
  font-weight: 500;
}
.quiz-tabs-wrap .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab:hover {
  background: #03a791;
  color: #fff;
}
.quiz-tabs-wrap
  .ant-tabs.ant-tabs-card
  .ant-tabs-card-bar
  .ant-tabs-tab-active {
  background: #03a791;
  color: #fff;
  border-color: #03a791;
}
.quiz-tabs-wrap .ant-tabs-nav.ant-tabs-nav-animated {
  width: calc(100% - 10px);
}

button.add-m-choice.bnt {
  padding: 0;
  width: auto;
  height: auto;
  min-width: auto;
  border: 0;
  position: absolute;
  right: 0;
  top: 30px;
}
button.add-m-choice i.material-icons {
  font-size: 30px;
  padding: 0;
}
button.add-m-choice.non-active i.material-icons {
  color: #d0021b;
}
.tbs-quiz-choice {
  position: relative;
}
.tbs-quiz-choice .cu-checkbox {
  margin-top: 5px;
  margin-bottom: 10px;
}
.tbs-quiz-choice input {
  width: calc(100% - 35px) !important;
}
.max-cho-t-show fieldset.sf-form > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.max-cho-t-show .sf-form label {
  margin: 0;
}
.max-cho-t-show .sf-form input[type="text"] {
  min-width: 80px;
  margin-left: 10px;
  width: 80px;
}
.quiz-settings .cu-checkbox {
  margin: 10px 0;
}
.qz-st-pass h3 {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #222;
}
.qz-st-input label {
  display: none;
}
.qz-st-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.qz-st-input .qz-or {
  padding: 0 10px;
  color: #bebebe;
}
.quiz-settings {
  margin-top: 10px;
}
.logo-upload.up-course-files {
  display: inline-block;
  width: 100%;
}
.logo-upload.up-course-files .ant-upload-list-picture-card-container {
  margin: 8px 8px 0 0;
}
.autoheight-box .ant-select-selection {
  height: auto;
  min-height: 35px;
}
.so-check-role fieldset.bcc-userid {
  width: 100%;
}
img.cover-img-vid {
  width: 100px;
  display: block;
}

.logo-upload.scope-upload .ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin: 0;
}
.logo-upload.scope-upload .ant-upload.ant-upload-drag p.ant-upload-drag-icon i {
  font-size: 21px;
  color: #03a791;
}
.logo-upload.scope-upload .ant-upload.ant-upload-drag p.ant-upload-text {
  font-weight: 500;
  opacity: 0.8;
}
.full-width-pic.up-course-files .ant-upload.ant-upload-drag {
  width: 100%;
}
.qiz-dlt-bnt {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.qiz-dlt-bnt fieldset.form-group.sf-form.select-wibg {
  flex: 1;
  padding-right: 10px;
}
.qiz-dlt-bnt button.normal-bnt {
  font-size: 16px;
}

.vido-covr a {
  display: block;
}
.vido-covr a i {
  font-size: 4em;
}

.risk-asses-img {
  max-width: 100%;
}

.swms-act-popup {
  width: 80%;
}
.swms-act-popup .org-user-table .tr .td:last-child {
  text-align: left;
}

.swms-info-size{
  font-size: 20px !important;
}
.ant-select-dropdown.expand-dropdwn{
  min-width: 200px;
  max-width: 100%;

}

.ant-select-dropdown.extra-expand-dropdwn{
  min-width: 330px;
  max-width: 100%;
}

.swms-big-tb-scroll {
  width: 800px;
  overflow-x: scroll;
  overflow-y: auto;
  padding-right: 20px;
}

.swms-big-tb-scroll::-webkit-scrollbar {
  width: 2px;
  border-radius: 100px;
}

.swms-big-tb-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.swms-big-tb-scroll::-webkit-scrollbar-thumb {
  background: rgba(45, 59, 72, 0.1);
}

.swms-big-tb-scroll:hover::-webkit-scrollbar-thumb {
  background: rgba(45, 59, 72, 0.2);
}

.swms-big-tb-scroll .sf-c-table {
  display: table;
  min-width: 2500px;
  margin-bottom: 1rem;
}

.swms-big-tb-scroll .sm-frm-tbl {
  min-width: 2500px;
}

.swms-big-tb-scroll .rdw-editor-toolbar {
  margin-bottom: 0;
  border-radius: 4px 4px 0 0;
}

.swms-big-tb-scroll .public-DraftStyleDefault-block {
  margin: 0;
  padding: 10px;
}

.swms-big-tb-scroll .rdw-editor-main {
  border: 1px solid #f1f1f1;
  border-top: 0;
  background: #fff;
  border-radius: 0 0 4px 4px;
}

.swms-big-tb-scroll .tr .th:nth-child(1),
.swms-big-tb-scroll .tr .td:nth-child(1) {
  width: 5%;
}

.swms-big-tb-scroll .tr .td:nth-child(2),
.swms-big-tb-scroll .tr .th:nth-child(2) {
  width: 5%;
}

.swms-big-tb-scroll .tr .td:nth-child(3),
.swms-big-tb-scroll .tr .th:nth-child(3) {
  width: 15%;
}

.swms-big-tb-scroll .tr .td:nth-child(4),
.swms-big-tb-scroll .tr .th:nth-child(4) {
  width: 5%;
}

.swms-big-tb-scroll .tr .td:nth-child(5),
.swms-big-tb-scroll .tr .th:nth-child(5) {
  width: 5%;
}

.swms-big-tb-scroll .tr .td:nth-child(6),
.swms-big-tb-scroll .tr .th:nth-child(6) {
  width: 5%;
}

.swms-big-tb-scroll .tr .td:nth-child(7),
.swms-big-tb-scroll .tr .th:nth-child(7) {
  width: 15%;
}

.swms-big-tb-scroll .tr .td:nth-child(8),
.swms-big-tb-scroll .tr .th:nth-child(8) {
  width: 5%;
}

.swms-big-tb-scroll .tr .td:nth-child(9),
.swms-big-tb-scroll .tr .th:nth-child(9) {
  width: 5%;
}

.swms-big-tb-scroll .tr .td:nth-child(10),
.swms-big-tb-scroll .tr .th:nth-child(10) {
  width: 5%;
}

.swms-big-tb-scroll .tr .td:nth-child(11),
.swms-big-tb-scroll .tr .th:nth-child(11) {
  width: 5%;
}

.swms-big-tb-scroll .tr .td:nth-child(12),
.swms-big-tb-scroll .tr .th:nth-child(12) {
  width: 10%;
}

.swms-big-tb-scroll .tr .td:nth-child(13),
.swms-big-tb-scroll .tr .th:nth-child(13) {
  width: 3%;
}

.swms-big-tb-scroll .tr .td:nth-child(14),
.swms-big-tb-scroll .tr .th:nth-child(14) {
  width: 2%;
  vertical-align: top;
}

.autohtxtbox .ant-select-selection {
  max-height: 200px;
  overflow: hidden;
}

.swms-big-tb-scroll .tr .td:nth-child(5),
.swms-big-tb-scroll .tr .th:nth-child(5) {
  width: 5%;
}

.swms-big-tb-scroll .tr .td:nth-child(6),
.swms-big-tb-scroll .tr .th:nth-child(6) {
  width: 5%;
}

.swms-big-tb-scroll .tr .td:nth-child(7),
.swms-big-tb-scroll .tr .th:nth-child(7) {
  width: 15%;
}

.swms-big-tb-scroll .tr .td:nth-child(8),
.swms-big-tb-scroll .tr .th:nth-child(8) {
  width: 5%;
}

.swms-big-tb-scroll .tr .td:nth-child(9),
.swms-big-tb-scroll .tr .th:nth-child(9) {
  width: 5%;
}

.swms-big-tb-scroll .tr .td:nth-child(10),
.swms-big-tb-scroll .tr .th:nth-child(10) {
  width: 5%;
}

.swms-big-tb-scroll .tr .td:nth-child(11),
.swms-big-tb-scroll .tr .th:nth-child(11) {
  width: 5%;
}

.swms-big-tb-scroll .tr .td:nth-child(12),
.swms-big-tb-scroll .tr .th:nth-child(12) {
  width: 10%;
}

.swms-big-tb-scroll .tr .td:nth-child(13),
.swms-big-tb-scroll .tr .th:nth-child(13) {
  width: 3%;
}

.swms-big-tb-scroll .tr .td:nth-child(14),
.swms-big-tb-scroll .tr .th:nth-child(14) {
  width: 2%;
  vertical-align: top;
}

.autohtxtbox .ant-select-selection {
  max-height: 200px;
  overflow: hidden;
}

.service-agent-title {
  color: #222222;
  font-weight: 600;
}

.split-delete-bnt {
  float: right;
}


/* .job-allocation-panel .task-data-column .esti-hrs-hd label {
color: #222222;
} */

.rdw-image-modal-size,
.rdw-image-modal-btn-section {
  display: none;
}

.ico-red {
  color: #ff0000;
}

.material-icons.ico-red {
  color: #ff0000;
}

.swms-big-tb-scroll .tr .td:nth-child(2),
.swms-big-tb-scroll .tr .th:nth-child(2) {
  width: 5%;
}

.swms-big-tb-scroll .tr .td:nth-child(3),
.swms-big-tb-scroll .tr .th:nth-child(3) {
  width: 15%;
}

.swms-big-tb-scroll .tr .td:nth-child(5),
.swms-big-tb-scroll .tr .th:nth-child(5) {
  width: 5%;
}

.swms-big-tb-scroll .tr .td:nth-child(6),
.swms-big-tb-scroll .tr .th:nth-child(6) {
  width: 5%;
}

.swms-big-tb-scroll .tr .td:nth-child(7),
.swms-big-tb-scroll .tr .th:nth-child(7) {
  width: 15%;
}

.swms-big-tb-scroll .tr .td:nth-child(8),
.swms-big-tb-scroll .tr .th:nth-child(8) {
  width: 5%;
}

.swms-big-tb-scroll .tr .td:nth-child(9),
.swms-big-tb-scroll .tr .th:nth-child(9) {
    width: 5%;
}

.swms-big-tb-scroll .tr .td:nth-child(10),
.swms-big-tb-scroll .tr .th:nth-child(10) {
    width: 5%;
}

.swms-big-tb-scroll .tr .td:nth-child(11),
.swms-big-tb-scroll .tr .th:nth-child(11) {
    width: 5%;
}

.swms-big-tb-scroll .tr .td:nth-child(12),
.swms-big-tb-scroll .tr .th:nth-child(12) {
    width: 10%;
}

.swms-big-tb-scroll .tr .td:nth-child(13),
.swms-big-tb-scroll .tr .th:nth-child(13) {
    width: 3%;
}

.swms-big-tb-scroll .tr .td:nth-child(14),
.swms-big-tb-scroll .tr .th:nth-child(14) {
    width: 2%;
    vertical-align: top;
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .boxes-documents .col-sm-12 {
      max-width: 100% !important;
  }

  .dash-tsklist .tsjd-lst {
      max-width: 100% !important;
  }

  .ant-table-layout-fixed table {
      width: 300% !important;
  }

  #pdfobject {
      min-height: 65vh !important;
  }

  .pdf-main-mdl .pdfobject {
      min-height: 65vh !important;
  }

  .pdf-main-mdl {
      min-height: 65vh !important;
  }

  .ant-modal {
      max-width: 90% !important;
  }

  .ant-modal {
      top: 10px !important;
  }

  .main-filelds-frms .sf-form {
      max-width: 100% !important;
      width: 100% !important;
  }

  .headcol {
      position: relative !important;
      margin-left: 0px !important;
      width: auto !important;
  }

  .payroll-headcol {
      position: relative !important;
      margin-left: 0px !important;
      width: auto !important;
  }

  .swms-big-tb-scroll .payrate-leftfixed {
      margin-left: 0px !important;
  }

  .swms-big-tb-scroll .payroll-leftfixed {
      margin-left: 0px !important;
  }

  .btn-headcol {
      position: relative !important;
      margin-left: 0px !important;
      width: auto !important;
  }

  .swms-big-tb-scroll .job-leftfixed {
      margin-left: 0px !important;
  }

  .headcolnew1 {
      position: relative !important;
      margin-left: 0 !important;
  }

  .headcolnew2 {
      position: relative !important;
      margin-left: 0 !important;
  }

  .headcolnew3 {
      position: relative !important;
      margin-left: 0 !important;
  }

  .swms-big-tb-scroll .job-leftfixed {
      margin-left: auto !important;
  }
}

.swms-big-tb-scroll .payrate-leftfixed {
  /* width: 500px; */
  overflow-x: scroll;
  margin-left: 280px;
  overflow-y: visible;
  padding: 0;
}

.swms-big-tb-scroll .payroll-leftfixed {
  /* width: 500px; */
  overflow-x: scroll;
  margin-left: 280px;
  overflow-y: visible;
  padding: 0;
}

.swms-big-tb-scroll .job-leftfixed {
  /* width: 500px; */
  overflow-x: scroll;
  margin-left: 390px;
  overflow-y: visible;
  padding: 0;
  margin-right: 185px;
}
.swms-big-tb-scroll .job-leftfixed1 {
  overflow-x: scroll;
}
.swms-big-tb-scroll .public-DraftStyleDefault-block{
  margin: 0;
padding: 2px;
}

.ant-select-dropdown.expand-dropdwn{
    min-width: 330px;
    max-width: 100%;

}
 .ant-select-dropdown.extra-expand-dropdwn{
    min-width: 450px;
    max-width: 100%;
 }
