
@media screen and (max-width:991px){
    .lbr-1{
        border-left: 0;
      }
}
@media screen and (max-width:872px){
    .sf-st-item.sf-prog{
        margin-left: 20px;
    }
}

@media screen and (max-width:780px){
    .sf-steps-status{
        order: 3;
        width: 100%;
        margin-top: 15px;
        border-top: 1px solid #f2f2f2;
        padding: 15px 0 0;
    }
    .sf-st-item.sf-prog {
        margin-left: 0;
    }
    .dash-header{
        flex-wrap: wrap;
    }

    .intro-video iframe{
        height:350px;
     }
     .v-all-org{font-size: 10px;}
}

@media screen and (max-width:767px){
    .sf-search-body{display: none;}
}

@media screen and (max-width:600px){
    .ant-layout-sider{
      position: fixed!important;
      left: 0;
      top: 0;
      height: 100%;
      z-index: 999;
    }

    .intro-video iframe{
        height:300px;
     }
  }

  @media screen and (max-width:400px){
    .intro-video iframe{
        height:200px;
     }
   }

  @media screen and (max-width:800px){
    .ant-modal.sf-intro-modal{
        width: calc(100vw - 50px)!important;
    }
}

@media screen and (max-width:500px){
    .sf-login{width: 100%;
      padding: 0 30px;
    }
  }

  @media screen and (min-width:992px) and (max-width:1200px){
    .sf-exp-dates{
        flex-direction: column;
        margin-right: 0;
    }
    .sf-exp-dates .sf-form {
        padding-right: 0;
        width: 100%;
      }
  }

@media screen and (max-width:500px){
    .sf-table-scroll {
    width: 100%;
    overflow-x: scroll;
    }
}
@media screen and (min-width:1600px){
    .swms-table-scroll{
      max-width:100%;
    }
  }